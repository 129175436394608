<template>
  <div class="edit-subcategory-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Activity' }"
        >活動管理
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'ActivityCategory' }"
        >類別管理
      </el-breadcrumb-item>
      <el-breadcrumb-item
        :to="{ name: 'DetailActivityCategory', params: { id: categoryId } }"
        >類別內容</el-breadcrumb-item
      >
      <el-breadcrumb-item>編輯次類別</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <el-form
        ref="subcategoryForm"
        :model="subcategory_form"
        :rules="subcategory_rules"
      >
        <el-form-item label="中文名稱" prop="name_zh">
          <el-input
            v-model="subcategory_form.name_zh"
            maxlength="64"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="英文名稱" prop="name_en">
          <el-input
            v-model="subcategory_form.name_en"
            maxlength="64"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="flex-end">
            <el-button type="warning" @click="handleRedirect">回主頁</el-button>
            <el-button type="success" @click="handleSubmit">送出</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  getActivitySubcategory,
  updateActivitySubcategory,
} from "@/api/activity-subcategory";

export default {
  name: "EditSubcategory",
  data() {
    return {
      subcategory_form: {
        name_zh: "",
        name_en: "",
      },
      subcategory_rules: {
        name_zh: [
          { required: true, message: "請輸入中文名稱", trigger: "blur" },
          { max: 64, message: "長度過長", trigger: "blur" },
        ],
        name_en: [
          { required: true, message: "請輸入英文名稱", trigger: "blur" },
          { max: 64, message: "長度過長", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    categoryId() {
      return +this.$route.params.cid;
    },
    subcategoryId() {
      return +this.$route.params.scid;
    },
  },
  created() {
    this.handleSubcategory();
  },
  methods: {
    async handleSubcategory() {
      const subcategory = await getActivitySubcategory(this.subcategoryId);
      this.subcategory_form = { ...this.subcategory_form, ...subcategory };
    },
    async handleRedirect() {
      await this.$router.push({
        name: "DetailActivityCategory",
        params: { id: this.categoryId },
      });
    },
    handleSubmit() {
      this.$refs.subcategoryForm.validate(async (valid) => {
        if (valid) {
          await updateActivitySubcategory(
            this.subcategoryId,
            this.subcategory_form
          );

          this.$message({
            type: "success",
            message: "刪除成功",
          });

          await this.handleRedirect();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
