import { render, staticRenderFns } from "./edit-subcategory.vue?vue&type=template&id=466f5d6a&scoped=true&"
import script from "./edit-subcategory.vue?vue&type=script&lang=js&"
export * from "./edit-subcategory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "466f5d6a",
  null
  
)

export default component.exports