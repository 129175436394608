import request from "@/utils/request";

/**
 * Get subcategories.
 *
 * @param {object} params
 * @param {number} params.category_id
 */
export function getSubcategories(params) {
  return request({
    url: `api/v1/activities-subcategories`,
    method: "GET",
    params,
  });
}

/**
 * Get subcategories with pagination.
 *
 * @param {number} category_id
 * @param {object} [params]
 * @param {number} params.category_id
 * @param {number} params.limit
 * @param {number} params.page
 */
export function getSubcategoriesPagination(category_id, params) {
  params.category_id = category_id;

  return request({
    url: `api/v1/activities-subcategories/page`,
    method: "GET",
    params,
  });
}

/**
 * Get subcategory.
 *
 * @param {number} subcategory_id
 */
export function getActivitySubcategory(subcategory_id) {
  return request({
    url: `api/v1/activities-subcategories/${subcategory_id}`,
    method: "GET",
  });
}

/**
 * Update subcategory.
 *
 * @param {number} subcategory_id
 * @param {object} data
 * @param {string} data.name_zh
 * @param {string} data.name_en
 */
export function updateActivitySubcategory(subcategory_id, data) {
  return request({
    url: `api/v1/activities-subcategories/${subcategory_id}`,
    method: "PATCH",
    data,
  });
}

/**
 * Create subcategory.
 *
 * @param {Object} data
 * @param {number} data.category_id
 * @param {string} data.name_zh
 * @param {string} data.name_en
 */
export function createActivitySubcategory(data) {
  return request({
    url: `api/v1/activities-subcategories`,
    method: "POST",
    data,
  });
}

/**
 * Delete subcategory.
 *
 * @param {number} subcategory_id
 */
export function deleteActivitySubcategory(subcategory_id) {
  return request({
    url: `api/v1/activities-subcategories/${subcategory_id}`,
    method: "DELETE",
  });
}
